import HTMLEntityHelper from 'he';
import { merge } from 'lodash';
import sanitizeHtml from 'sanitize-html';
import xss, { escapeAttrValue, safeAttrValue, whiteList } from 'xss';

export const sanitizeObject = (targetObject: object) => {
  const sanitizedObject = targetObject;

  for (const [key, value] of Object.entries(sanitizedObject)) {
    if (typeof value === 'string') {
      sanitizedObject[key] = sanitizeHtml(value, {
        allowedTags: [],
        allowedAttributes: {}
      });
    }
  }

  return sanitizedObject;
};

const preProcessHTMLForQuoteTypes = (htmlContent: string) => {
  const quoteMap = {};
  const attributeQuoteRegex = /<(\w+)\s+[^>]*?(\w+)=(['"])/g;
  let match;

  while ((match = attributeQuoteRegex.exec(htmlContent)) !== null) {
    const [, tagName, attrName, quoteType] = match;
    const key = `${tagName.toLowerCase()}:${attrName.toLowerCase()}`;
    quoteMap[key] = quoteType === "'" ? 'single' : 'double';
  }

  return quoteMap;
};

const wrapInQuoteType = (content: string, quoteType: "'" | '"') => `${quoteType}${content}${quoteType}`;

export const sanitizeContent = ({
  content,
  sanitizationLevel = 'basic',
  postDecoding = false
}: {
  content: string;
  sanitizationLevel?: 'basic' | 'advanced';
  postDecoding?: boolean;
}) => {
  if (!content) return content;

  try {
    if (sanitizationLevel === 'advanced') {
      // Remove script tag with script code inside it
      content = content.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
      // Remove script without closing tag
      content = content.replace(/<script(.*?)>/gi, '');
      // Remove script with missing / to close tag (Firefox XSS)
      content = content.replace(/<script(.*?)\/>/gi, '');

      const quoteTypeMap = preProcessHTMLForQuoteTypes(content);

      const sanitizedContent = xss(content, {
        onTag: function (tag) {
          // Remove tags
          return customXSSBlackList.includes(tag) ? '' : undefined;
        },
        onTagAttr: function (tag, name, value) {
          const key = `${tag.toLowerCase()}:${name.toLowerCase()}`;
          const quoteType = quoteTypeMap[key] === 'single' ? "'" : '"';

          if (name === 'style') {
            // Remove css comments and comment's characters
            const cleanedFromCSSCommentsValue = value
              .replace(/\s*(?!<")\/\*[^*]+\*\/(?!")\s*/gi, '')
              .replace(/\/\*|\*\//gi, '');
            const safeValue = safeAttrValue(tag, name, cleanedFromCSSCommentsValue, { process: (string) => string });

            return `${name}=${wrapInQuoteType(safeValue, quoteType)}`;
          }

          if (tag === 'img' && name === 'src' && value.startsWith('cid:')) {
            const escapedString = escapeAttrValue(value);
            return `${name}=${wrapInQuoteType(escapedString, quoteType)}`;
          }

          if (tag === 'a' && name === 'href') {
            // Remove IPv4 from links
            let formattedValue = value.replace(
              /(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/gi,
              ''
            );
            formattedValue = decodeURI(formattedValue);
            const safeValue = safeAttrValue(tag, name, formattedValue, { process: (string) => string });

            // Escape DWORD Encoding IP
            if (safeValue.match(/(?<=\/\/)([0-9]+)(?=\/)/)) {
              return `${name}=""`;
            }

            return `${name}=${wrapInQuoteType(safeValue, quoteType)}`;
          }

          // Default sanitization for value
          if (name === 'src' || name === 'background') {
            const safeValue = safeAttrValue(tag, name, value, { process: (string) => string });
            return `${name}=${wrapInQuoteType(safeValue, quoteType)}`;
          }

          // Outlook MsoNormal class
          if (tag === 'p' && name === 'class' && value === 'MsoNormal') {
            return `${name}=${wrapInQuoteType(value, quoteType)}`;
          }

          return;
        },
        whiteList: merge(whiteList, customXSSWhiteList)
      });

      return postDecoding ? HTMLEntityHelper.decode(sanitizedContent) : sanitizedContent;
    }
  } catch (error) {
    return basicContentSanitization({ content, postDecoding });
  }

  return basicContentSanitization({ content, postDecoding });
};

export const basicContentSanitization = ({ content, postDecoding }: { content: string; postDecoding: boolean }) => {
  if (postDecoding) {
    return HTMLEntityHelper.decode(
      xss(content, {
        whiteList: {}
      })
    );
  }

  return xss(content, {
    whiteList: {}
  });
};

export const lineBreaksToNewlines = (html: string): string => {
  return html.replaceAll('<br>', '\r\n');
};

export const removeTrailingLineBreaks = (html: string): string => {
  return html.replace(/(<br>\s*)+$/, '');
};

export const removeHTMLTags = (html: string, decodeHtml?: (value: string) => string, args?: { except?: string[] }) => {
  html = removeTrailingLineBreaks(html);

  const selfClosing: string[] = [];
  if (args?.except?.includes('br')) {
    selfClosing.push('br');
  } else {
    html = lineBreaksToNewlines(html);
  }

  if (decodeHtml) {
    html = decodeHtml(html);
  }

  return sanitizeHtml(html, {
    allowedTags: args?.except ?? [],
    allowedAttributes: {},
    allowedSchemes: [],
    selfClosing: selfClosing,
    disallowedTagsMode: 'discard'
  });
};

export const customXSSWhiteList = {
  html: [],
  head: [],
  body: [],
  title: [],
  svg: [],
  link: ['rel'],
  style: [],
  meta: [],
  iframe: [],
  bgsound: [],
  input: ['type'],
  object: [],
  embed: [],
  script: [],
  'o:p': [],
  base: [],
  fieldset: [],
  legend: []
};

export const customXSSBlackList = ['frame', 'frameset', '!doctype', 'label', '![if', '![endif]'];
