export const ACTIVE_FRONTEND_FEATURE_FLAGS = [
  'ENABLE_USER_TIME_LOGGING',
  'ENABLE_FLOATING_WINDOW',
  'WHATSAPP_ENABLED',
  'AUTOMATIC_CLOSING_TICKETS',
  'ALLOW_CHANGING_TO_FORBIDDEN_TICKET_TYPES',
  'ENABLE_COMMENT_IMAGES_TOGGLE',
  'ENABLE_COMMENT_IMAGES',
  'ENABLE_TICKET_TYPE_MANAGEMENT',
  'ENABLE_SURVEY_SETTINGS',
  'CHAT_SHOW_AGENT_TYPING_STATUS',
  'EMAIL_SEND_FROM_TOP',
  'EMAIL_SEND_FROM_BOTTOM',
  'SHOW_SEND_AND_CLOSE_AS_DONE',
  'ENABLE_GENERIC_PHONE_LINK_CLICK_TO_CALL',
  'ENABLE_EGAIN',
  'ON_CALL_OPEN_CALL_UI',
  'ENABLE_ELISAOC_SHOW_STATUS_WHEN_MINIMIZED',
  'ENABLE_COMMENT_HTML',
  'ENABLE_KNOWLEDGE_BASE',
  'ENABLE_CUSTOM_USER_TICKET_ORDERING_BY_DUEDATE',
  'ADD_DEFAULT_TICKET_TYPE_TO_TAG',
  'ENABLE_SEARCH_ENTITY_ID',
  'ENABLE_EXCLUSIVE_SEARCH',
  'ENABLE_TICKET_LIST_FILTERING',
  'ENABLE_TICKETLIST_PREVIEW',
  'ENABLE_START_WORKING_ON_FROM_TICKETLIST_BY_WHOLE_ELEMENT',
  'ENABLE_TICKETLIST_ORIGINAL_CONTACT',
  'ENABLE_TICKETLIST_LAST_CONTACT_ADDRESS',
  'ENABLE_TICKETLIST_ORIGINAL_DIRECTION',
  'ENABLE_TICKETLIST_CREATED_TIMESTAMP',
  'ENABLE_TICKETLIST_TOUCHED_TIMESTAMP',
  'ENABLE_TICKETLIST_DUEDATE_TIMESTAMP',
  'EMBEDDED_ATTACHMENTS_LIST_CLOSED',
  'ENABLE_ENTITY_TAGS',
  'DISABLE_TASK_CONTENTS',
  'LIMIT_CLICKTOSEARCH_ENTITYTYPES',
  'ENABLE_SURVEY_TICKET_INFO',
  'ALLOW_TAG_ADDITION_FROM_TASK',
  'ENABLE_CLICK_TO_SEARCH',
  'ENABLE_OC_CLICK_TO_CALL',
  'ENABLE_RING_CLICK_TO_CALL',
  'ENABLE_MITEL_CLICK_TO_CALL',
  'ENABLE_ENREACH_VOICE_CLICK_TO_CALL',
  'ENABLE_CHANGE_STATUS_TO_DOING',
  'ENABLE_RETURN_AS_NEW',
  'ENABLE_EIDENTIFICATION',
  'ENABLE_AI_ASSISTANT',
  'ENABLE_OPENAI',
  'ENABLE_START_WORKING_ON_FROM_TICKETLIST',
  'CALLS_TO_SAME_TICKET',
  'OPEN_EXTERNAL_LINK_ON_PHONE_CALL',
  'EXPORT_DATA',
  'ENABLE_ENTITY_VIEWER',
  'ENABLE_TABBAR_PREVIEW',
  'CHAT_ANCHOR_TICKET_BY_DEFAULT',
  'ENABLE_FILTER_SHORT_PHONE_NUMBERS',
  'ENABLE_LINK_PARSE',
  'ENABLE_IMG_PARSE',
  'SECURE_MAIL_ENABLED',
  'FACEBOOK_CHAT_ENABLED',
  'GIOSG_CHAT_ENABLED',
  'CUSTOMER_PORTAL_ENABLED',
  'ENABLE_SOUND_NOTIFICATION',
  'HTTPS_LINK_PARSE',
  'ENABLE_ENTITY_SEARCH_ON_BLUR',
  'ARCHIVAL_SINGLE_TEMPLATE_ENABLED',
  'MANUALLY_ANONYMIZE_TICKET',
  'MANUALLY_ANONYMIZE_COMMENT',
  'DISABLE_INSERT_AT_CURSOR'
] as const;

export type TFeatureFlag = (typeof ACTIVE_FRONTEND_FEATURE_FLAGS)[number];

export default class FeatureFlags {
  static flags: TFeatureFlag[] = [];

  static setFlags(incomingFlags: TFeatureFlag[]) {
    this.flags = incomingFlags;
  }

  static isFlagOn(flag: TFeatureFlag) {
    return this.flags.includes(flag);
  }
}
