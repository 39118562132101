import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Header, Icon } from 'semantic-ui-react';

import type { Field } from '@eeedo/types';
import type { FC } from 'react';

import * as styles from '../FieldHeader/FieldHeader.style';
import { addEntityToCase } from 'src/actions/ticketsActions';
import { FieldActionType } from 'src/types/Info';

import type { State } from 'src/types/initialState';

interface ActionFieldProps {
  field: Field;
  taskId?: string | number;
  taskType?: string;
  values?: { [k: string]: any };
}

const ActionField: FC<ActionFieldProps> = ({ field, taskId, taskType, values }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const task = useSelector((state: State) => state.detailedTickets.find((ticket) => ticket.id === taskId));
  const isAttached = task?.entities.find((entity) => entity._id === values?.[field.value] && entity._type === field?.params?.entityType);

  const onClickAction = useCallback(() => {
    if (values?.[field.value]) {
      dispatch(
        addEntityToCase(taskId, {
          taskType: taskType,
          _id: values[field.value],
          _type: field?.params?.entityType
        })
      );
    }
  }, [dispatch, field, values, taskId, taskType]);

  if (field?.params?.actionType === FieldActionType.addEntity) {
    return (
      <div style={{ display: 'flex', padding: 11 }}>
        {isAttached ? (
          <Header as="h5" style={styles.header}>
            <Icon name="check" color="green" />
            {t('TICKET_CUSTOMER_ALREADY_ATTACHED_CARD')}
          </Header>
        ) : (
          <Button content={field?.name} onClick={onClickAction} size="tiny" />
        )}
      </div>
    );
  }

  return null;
};

export default ActionField;
