import { t } from 'i18next';
import moment from 'moment';

import ApiConfig from './ApiConfig';

export function defaultTask(defaultTaskType) {
  const newTask = new Task();
  newTask.title = t('MANUAL_INPUT');
  newTask.taskType = defaultTaskType;
  newTask.channel = 4;
  newTask.status = 'doing';

  return newTask;
}

/** @deprecated */
export class Task {
  active = true;
  id = 'new';
  type = 'task';
  createdByUser = 'USR1';
  created = moment().unix();
  touched = moment().unix();
  lastEdited = 0;
  editedByUser = '';
  title = t('TICKET_PLACEHOLDER_TITLE');
  content = t('TICKET_PLACEHOLDER_CONTENT');
  channel = 2;
  dueDate = moment().add(3, 'days').unix();
  status = 'todo';
  metaData = {};
  taskType = 'consumerCase';
  likes = 0;
  dislikes = 0;
  deprecated = false;
  numOfComments = 0;
  circles = [];
  mentionedUsers = [];
  delegatedTo = ['USR1'];
  comments = [];
  attachments = [];
  likedUsers = [];
  dislikedUsers = [];
  case = {};
  direction = 'in';
  product = {};
  entities = [];
  customer = {};
  lastContact = moment().unix();
  constructor() {
    this.tags = ApiConfig.getConfig().API_MAIN_CONTENT_TAGS;
  }
}
