import {
  RpaRuleActionEntity,
  RpaRuleActionType,
  RpaRuleDropdownType,
  RpaRuleReplyType,
  RpaRuleTrigger
} from '@eeedo/types';
import { t as tr } from 'i18next';

import type {
  Channel,
  Priority,
  ResponseTemplate,
  Suggestion,
  Tag,
  TicketType,
  TicketTypeMetadata,
  UserWithProfile,
  Webhook
} from '@eeedo/types';

import { getEntityTypes } from './utils';

import type { ConditionTargetType, DelayTargetType, DropdownTargetValuesMap } from './RuleModals/types';

export const ruleEventTypes = [
  {
    text: 'management.rules_management.rule_type.call_rescheduled',
    value: 'call_rescheduled',
    key: 'call_rescheduled'
  },
  {
    text: 'management.rules_management.rule_type.comment_added',
    value: 'comment',
    key: 'comment'
  },
  {
    text: 'management.rules_management.rule_type.helper_added',
    value: 'helper',
    key: 'helper'
  },
  {
    text: 'TICKET_DELEGATION_ADD_SUCCESS',
    value: 'delegation_added',
    key: 'delegation_added'
  },
  {
    text: 'TICKET_DELEGATION_REMOVE_SUCCESS',
    value: 'delegation_removed',
    key: 'delegation_removed'
  },
  {
    text: 'management.rules_management.rule_type.entity_attached',
    value: 'entity_attached',
    key: 'entity_attached'
  },
  {
    text: 'management.rules_management.rule_type.status_changed',
    value: 'status_changed',
    key: 'status_changed'
  },
  {
    text: 'management.rules_management.rule_type.taskType_changed',
    value: 'taskType_changed',
    key: 'taskType_changed'
  },
  {
    text: 'management.rules_management.rule_type.tag_added',
    value: 'tag_added',
    key: 'tag_added'
  }
];

export const conditionTypes = [
  {
    text: 'management.rules_management.condition_type.all',
    value: 'all'
  },
  {
    text: 'management.rules_management.condition_type.any',
    value: 'any'
  }
];

export const ruleFields = {
  case: {
    text: 'management.rules_management.condition_target_types.case_details',
    value: '$.case'
  },
  commentChannel: {
    text: 'management.rules_management.condition_target_types.comment_channel',
    value: '$.comment.channel',
    dropdownType: RpaRuleDropdownType.CHANNEL
  },
  commentContent: {
    text: 'management.rules_management.condition_target_types.content',
    value: '$.comment.content'
  },
  commentDirection: {
    text: 'management.rules_management.condition_target_types.comment_direction',
    value: '$.comment.direction',
    dropdownType: RpaRuleDropdownType.DIRECTION
  },
  commentMetaDataFrom: {
    text: 'management.rules_management.condition_target_types.sender_email',
    value: '$.comment.metaData.from'
  },
  commentMetaDataHeadersXEnvelopeTo: {
    text: 'management.rules_management.condition_target_types.technical_address',
    value: '$.comment.metaData.headers.x-envelope-to'
  },
  commentMetaDataTo: {
    text: 'management.rules_management.condition_target_types.recipient_email',
    value: '$.comment.metaData.to'
  },
  commentTitle: {
    text: 'management.rules_management.condition_target_types.comment_title',
    value: '$.comment.title'
  },
  commentTaskType: {
    text: 'management.rules_management.condition_target_types.comment_type',
    value: '$.comment.taskType'
  },
  content: {
    text: 'management.rules_management.condition_target_types.content',
    value: '$.content'
  },
  direction: {
    text: 'management.rules_management.condition_target_types.comment_direction',
    value: '$.direction',
    dropdownType: RpaRuleDropdownType.DIRECTION
  },
  entity: {
    text: 'management.rules_management.condition_target_types.entity_field',
    value: '$.entity'
  },
  eIdentification: {
    text: 'management.rules_management.condition_target_types.e_identification_status',
    value: '$.eIdentification.status',
    dropdownType: RpaRuleDropdownType.STATUS
  },
  metaDataFrom: {
    text: 'management.rules_management.condition_target_types.sender_email',
    value: '$.metaData.from'
  },
  metaDataHeadersXEnvelopeTo: {
    text: 'management.rules_management.condition_target_types.technical_address',
    value: '$.metaData.headers.x-envelope-to'
  },
  metaDataTo: {
    text: 'management.rules_management.condition_target_types.recipient_email',
    value: '$.metaData.to'
  },
  ticketChannel: {
    text: 'management.rules_management.condition_target_types.channel',
    value: '$.ticket.channel',
    dropdownType: RpaRuleDropdownType.CHANNEL,
    multiple: true
  },
  ticketContent: {
    text: 'management.rules_management.condition_target_types.content',
    value: '$.ticket.content'
  },
  ticketCreated: {
    text: 'management.rules_management.condition_target_types.ticket_created',
    value: '$.ticket.created',
    dropdownType: RpaRuleDropdownType.TIME_UNITS
  },
  ticketDelegatedTo: {
    text: 'management.rules_management.condition_target_types.delegate',
    value: '$.ticket.delegatedTo',
    dropdownType: RpaRuleDropdownType.TICKET_DELEGATIONS,
    multiple: true
  },
  ticketPreviousTaskType: {
    text: 'management.rules_management.condition_target_types.previous_ticket_type',
    value: '$.ticket.previousTaskType',
    dropdownType: RpaRuleDropdownType.TICKET_TYPE,
    multiple: true
  },
  ticketStatus: {
    text: 'CASE_TITLE_HANDLESTATUS',
    value: '$.ticket.status',
    dropdownType: RpaRuleDropdownType.TICKET_STATE,
    multiple: true
  },
  ticketTags: {
    text: 'CASE_TITLE_TAGS',
    value: '$.ticket.tags',
    dropdownType: RpaRuleDropdownType.TICKET_TAG,
    multiple: true
  },
  ticketTaskType: {
    text: 'management.rules_management.condition_target_types.ticket_type',
    value: '$.ticket.taskType',
    dropdownType: RpaRuleDropdownType.TICKET_TYPE,
    multiple: true
  },
  ticketTitle: {
    text: 'management.rules_management.condition_target_types.ticket_title',
    value: '$.ticket.title'
  },
  ticketLastEdited: {
    text: 'management.rules_management.delay_target_types.ticket_lastEdited',
    value: '$.ticket.lastEdited'
  },
  ticketDueDate: {
    text: 'management.rules_management.delay_target_types.ticket_dueDate',
    value: '$.ticket.dueDate'
  },
  ticketStartedWorking: {
    text: 'management.rules_management.delay_target_types.ticket_startedWorking',
    value: '$.ticket.startedWorking'
  },
  ticketFinishedWorking: {
    text: 'management.rules_management.delay_target_types.ticket_finishedWorking',
    value: '$.ticket.finishedWorking'
  },
  ticketLastContact: {
    text: 'management.rules_management.delay_target_types.ticket_lastContact',
    value: '$.ticket.lastContact'
  },
  ticketFirstResponseTime: {
    text: 'management.rules_management.delay_target_types.ticket_firstResponseTime',
    value: '$.ticket.firstResponseTime'
  },
  ticketEntityTypes: {
    text: 'management.rules_management.condition_target_types.ticket_entity_types',
    value: '$.ticket.entityTypes',
    multiple: true,
    dropdownType: RpaRuleDropdownType.ENTITY_TYPES
  },
  times: {
    text: 'management.rules_management.condition_target_types.number_of_tries',
    value: '$.times'
  },
  title: {
    text: 'management.rules_management.condition_target_types.comment_title',
    value: '$.title'
  }
};

const defaultConditionTargetTypes = [
  ruleFields.ticketTitle,
  ruleFields.ticketContent,
  ruleFields.ticketTags,
  ruleFields.ticketStatus,
  ruleFields.ticketChannel,
  ruleFields.ticketTaskType,
  ruleFields.ticketEntityTypes,
  ruleFields.case
];

const eIdentificationConditionTargetTypes = [ruleFields.eIdentification];

export const conditionTargetTypes: {
  [key in RpaRuleTrigger]: ConditionTargetType[];
} = {
  [RpaRuleTrigger.CallRescheduled]: [ruleFields.times],
  [RpaRuleTrigger.Comment]: [
    ruleFields.commentTitle,
    ruleFields.ticketTitle,
    ruleFields.commentContent,
    ruleFields.commentMetaDataFrom,
    ruleFields.commentMetaDataTo,
    ruleFields.commentDirection,
    ruleFields.commentChannel,
    ruleFields.ticketTaskType,
    ruleFields.ticketChannel,
    ruleFields.ticketTags,
    ruleFields.commentMetaDataHeadersXEnvelopeTo,
    ruleFields.ticketDelegatedTo,
    ruleFields.ticketCreated,
    ruleFields.case
  ],
  [RpaRuleTrigger.Helper]: [
    ruleFields.title,
    ruleFields.ticketTitle,
    ruleFields.content,
    ruleFields.metaDataFrom,
    ruleFields.metaDataTo,
    ruleFields.direction,
    ruleFields.commentChannel,
    ruleFields.ticketTaskType,
    ruleFields.ticketChannel,
    ruleFields.ticketTags,
    ruleFields.metaDataHeadersXEnvelopeTo,
    ruleFields.ticketDelegatedTo,
    ruleFields.ticketCreated,
    ruleFields.case
  ],
  [RpaRuleTrigger.DelegationAdded]: [...defaultConditionTargetTypes],
  [RpaRuleTrigger.DelegationRemoved]: [...defaultConditionTargetTypes],
  [RpaRuleTrigger.TaskTypeChanged]: [...defaultConditionTargetTypes, ruleFields.ticketPreviousTaskType],
  [RpaRuleTrigger.EntityAttached]: [ruleFields.entity, ...defaultConditionTargetTypes],
  [RpaRuleTrigger.StatusChanged]: [...defaultConditionTargetTypes, ...eIdentificationConditionTargetTypes],
  [RpaRuleTrigger.TagAdded]: [...defaultConditionTargetTypes, ruleFields.ticketPreviousTaskType]
};

const defaultDelayTargetTypes = [
  ruleFields.ticketCreated,
  ruleFields.ticketLastEdited,
  ruleFields.ticketDueDate,
  ruleFields.ticketStartedWorking,
  ruleFields.ticketFinishedWorking,
  ruleFields.ticketLastContact,
  ruleFields.ticketFirstResponseTime,
  ruleFields.case
];

export const delayTargetTypes: {
  [key in RpaRuleTrigger]?: DelayTargetType[];
} = {
  [RpaRuleTrigger.CallRescheduled]: [],
  [RpaRuleTrigger.Comment]: [...defaultDelayTargetTypes],
  [RpaRuleTrigger.Helper]: [...defaultDelayTargetTypes],
  [RpaRuleTrigger.DelegationAdded]: [...defaultDelayTargetTypes],
  [RpaRuleTrigger.DelegationRemoved]: [...defaultDelayTargetTypes],
  [RpaRuleTrigger.TaskTypeChanged]: [...defaultDelayTargetTypes],
  [RpaRuleTrigger.EntityAttached]: [...defaultDelayTargetTypes, ruleFields.entity],
  [RpaRuleTrigger.StatusChanged]: [...defaultDelayTargetTypes],
  [RpaRuleTrigger.TagAdded]: [...defaultDelayTargetTypes]
};

export const conditionOperators = [
  {
    text: 'management.rules_management.condition_operators.greater_than',
    value: 'greaterThan',
    targetTypesRelation: [ruleFields.times.value]
  },
  {
    text: 'management.rules_management.condition_operators.greater_than_inclusive',
    value: 'greaterThanInclusive',
    targetTypesRelation: [ruleFields.times.value]
  },
  {
    text: 'management.rules_management.condition_operators.contains',
    value: 'stringContains',
    targetTypesRelation: [
      ruleFields.commentTitle.value,
      ruleFields.commentContent.value,
      ruleFields.commentMetaDataFrom.value,
      ruleFields.commentMetaDataTo.value,
      ruleFields.commentMetaDataHeadersXEnvelopeTo.value,
      ruleFields.ticketTitle.value,
      ruleFields.ticketContent.value,
      ruleFields.entity.value,
      ruleFields.case.value
    ]
  },
  {
    text: 'management.rules_management.condition_operators.not_contains',
    value: 'stringNotContains',
    targetTypesRelation: [
      ruleFields.commentTitle.value,
      ruleFields.commentContent.value,
      ruleFields.commentMetaDataFrom.value,
      ruleFields.commentMetaDataTo.value,
      ruleFields.commentMetaDataHeadersXEnvelopeTo.value,
      ruleFields.ticketTitle.value,
      ruleFields.ticketContent.value,
      ruleFields.entity.value,
      ruleFields.case.value
    ]
  },
  {
    text: 'management.rules_management.condition_operators.equal',
    value: 'equal',
    targetTypesRelation: [
      ruleFields.commentTitle.value,
      ruleFields.commentContent.value,
      ruleFields.commentMetaDataFrom.value,
      ruleFields.commentMetaDataTo.value,
      ruleFields.commentTaskType.value,
      ruleFields.commentMetaDataHeadersXEnvelopeTo.value,
      ruleFields.commentDirection.value,
      ruleFields.commentChannel.value,
      ruleFields.ticketTitle.value,
      ruleFields.ticketContent.value,
      ruleFields.ticketTaskType.value,
      ruleFields.ticketPreviousTaskType.value,
      ruleFields.ticketChannel.value,
      ruleFields.ticketTags.value,
      ruleFields.ticketTags.value,
      ruleFields.ticketStatus.value,
      ruleFields.ticketDelegatedTo.value,
      ruleFields.entity.value,
      ruleFields.eIdentification.value,
      ruleFields.case.value
    ]
  },
  {
    text: 'management.rules_management.condition_operators.not_equal',
    value: 'notEqual',
    targetTypesRelation: [
      ruleFields.commentTitle.value,
      ruleFields.commentContent.value,
      ruleFields.commentMetaDataFrom.value,
      ruleFields.commentMetaDataTo.value,
      ruleFields.commentMetaDataHeadersXEnvelopeTo.value,
      ruleFields.ticketTitle.value,
      ruleFields.ticketContent.value,
      ruleFields.ticketTaskType.value,
      ruleFields.ticketTags.value,
      ruleFields.ticketChannel.value,
      ruleFields.ticketTaskType.value,
      ruleFields.ticketPreviousTaskType.value,
      ruleFields.ticketTags.value,
      ruleFields.ticketStatus.value,
      ruleFields.entity.value,
      ruleFields.eIdentification.value,
      ruleFields.case.value
    ]
  },
  {
    text: 'management.rules_management.condition_operators.older_than',
    value: 'olderThan',
    targetTypesRelation: [ruleFields.ticketCreated.value]
  },
  {
    text: 'management.rules_management.condition_operators.newer_than',
    value: 'newerThan',
    targetTypesRelation: [ruleFields.ticketCreated.value]
  },
  {
    text: 'management.rules_management.condition_operators.any_of_these',
    value: 'anyOfThese',
    targetTypesRelation: [
      ruleFields.ticketChannel.value,
      ruleFields.ticketStatus.value,
      ruleFields.ticketTags.value,
      ruleFields.ticketTaskType.value,
      ruleFields.ticketTaskType.value,
      ruleFields.ticketPreviousTaskType.value,
      ruleFields.ticketChannel.value,
      ruleFields.ticketDelegatedTo.value,
      ruleFields.ticketEntityTypes.value,
      ruleFields.ticketTags.value
    ]
  },
  {
    text: 'management.rules_management.condition_operators.none_of_these',
    value: 'noneOfThese',
    targetTypesRelation: [
      ruleFields.ticketChannel.value,
      ruleFields.ticketStatus.value,
      ruleFields.ticketTags.value,
      ruleFields.ticketTaskType.value,
      ruleFields.ticketTaskType.value,
      ruleFields.ticketPreviousTaskType.value,
      ruleFields.ticketChannel.value,
      ruleFields.ticketDelegatedTo.value,
      ruleFields.ticketEntityTypes.value,
      ruleFields.ticketTags.value
    ]
  }
];

export const actionsEntities: {
  text: string;
  value: RpaRuleActionEntity;
  relationsToRuleEventType: RpaRuleTrigger[];
  cssWrap: 'wrap' | 'nowrap';
}[] = [
  {
    text: 'management.rules_management.actions_entity_types.ticket',
    value: RpaRuleActionEntity.Ticket,
    relationsToRuleEventType: [
      RpaRuleTrigger.CallRescheduled,
      RpaRuleTrigger.Comment,
      RpaRuleTrigger.DelegationAdded,
      RpaRuleTrigger.DelegationRemoved,
      RpaRuleTrigger.EntityAttached,
      RpaRuleTrigger.Helper,
      RpaRuleTrigger.StatusChanged,
      RpaRuleTrigger.TagAdded,
      RpaRuleTrigger.TaskTypeChanged
    ],
    cssWrap: 'nowrap'
  },
  {
    text: 'management.rules_management.actions_entity_types.send_msg',
    value: RpaRuleActionEntity.NewComment,
    relationsToRuleEventType: [
      RpaRuleTrigger.CallRescheduled,
      RpaRuleTrigger.Comment,
      RpaRuleTrigger.DelegationAdded,
      RpaRuleTrigger.DelegationRemoved,
      RpaRuleTrigger.EntityAttached,
      RpaRuleTrigger.Helper,
      RpaRuleTrigger.StatusChanged,
      RpaRuleTrigger.TagAdded
    ],
    cssWrap: 'wrap'
  },
  {
    text: 'management.rules_management.actions_entity_types.attach_entity',
    value: RpaRuleActionEntity.AttachEntity,
    relationsToRuleEventType: [RpaRuleTrigger.EntityAttached],
    cssWrap: 'wrap'
  },
  {
    text: 'management.rules_management.actions_entity_types.ai_function',
    value: RpaRuleActionEntity.AiFunction,
    relationsToRuleEventType: [
      RpaRuleTrigger.CallRescheduled,
      RpaRuleTrigger.Comment,
      RpaRuleTrigger.DelegationAdded,
      RpaRuleTrigger.DelegationRemoved,
      RpaRuleTrigger.EntityAttached,
      RpaRuleTrigger.StatusChanged,
      RpaRuleTrigger.TagAdded
    ],
    cssWrap: 'nowrap'
  },
  {
    text: 'management.webhooks_management.labels.rpa_action',
    value: RpaRuleActionEntity.Webhook,
    relationsToRuleEventType: [
      RpaRuleTrigger.CallRescheduled,
      RpaRuleTrigger.Comment,
      RpaRuleTrigger.DelegationAdded,
      RpaRuleTrigger.DelegationRemoved,
      RpaRuleTrigger.EntityAttached,
      RpaRuleTrigger.Helper,
      RpaRuleTrigger.StatusChanged,
      RpaRuleTrigger.TagAdded
    ],
    cssWrap: 'nowrap'
  }
];

export const actionTypes: {
  text: string;
  value: RpaRuleActionType;
  actionEntity: RpaRuleActionEntity | null;
}[] = [
  {
    text: 'management.rules_management.action_types.title',
    value: RpaRuleActionType.TICKET_TITLE,
    actionEntity: RpaRuleActionEntity.Ticket
  },
  {
    text: 'management.rules_management.action_types.type',
    value: RpaRuleActionType.TICKET_TYPE,
    actionEntity: RpaRuleActionEntity.Ticket
  },
  {
    text: 'management.rules_management.action_types.due_data',
    value: RpaRuleActionType.TICKET_DUE_DATE,
    actionEntity: RpaRuleActionEntity.Ticket
  },
  {
    text: 'management.rules_management.action_types.priority',
    value: RpaRuleActionType.TICKET_PRIORITY,
    actionEntity: RpaRuleActionEntity.Ticket
  },
  {
    text: 'management.rules_management.action_types.state',
    value: RpaRuleActionType.TICKET_STATE,
    actionEntity: RpaRuleActionEntity.Ticket
  },
  {
    text: 'management.rules_management.action_types.delegate',
    value: RpaRuleActionType.TICKET_DELEGATIONS,
    actionEntity: RpaRuleActionEntity.Ticket
  },
  {
    text: 'management.rules_management.action_types.remove_delegations_of_ticket',
    value: RpaRuleActionType.REMOVE_ALL_TICKET_DELEGATIONS,
    actionEntity: RpaRuleActionEntity.Ticket
  },
  {
    text: 'management.rules_management.action_types.remove_ticket_working_on',
    value: RpaRuleActionType.REMOVE_TICKET_WORKING_ON,
    actionEntity: RpaRuleActionEntity.Ticket
  },
  {
    text: 'management.rules_management.action_types.tag',
    value: RpaRuleActionType.TICKET_TAG,
    actionEntity: RpaRuleActionEntity.Ticket
  },
  {
    text: 'management.rules_management.action_types.remove_tag',
    value: RpaRuleActionType.REMOVE_TICKET_TAG,
    actionEntity: RpaRuleActionEntity.Ticket
  },
  {
    text: 'management.rules_management.action_types.channel',
    value: RpaRuleActionType.TICKET_CHANNEL,
    actionEntity: RpaRuleActionEntity.Ticket
  },
  {
    text: 'management.rules_management.action_types.reply',
    value: RpaRuleActionType.REPLY_COMMENT,
    actionEntity: null
  },
  {
    text: 'management.rules_management.action_types.rpa_action',
    value: RpaRuleActionType.WEBHOOK,
    actionEntity: RpaRuleActionEntity.Webhook
  },
  {
    text: 'management.rules_management.action_types.ai_function',
    value: RpaRuleActionType.AI_FUNCTION_INVOCATION,
    actionEntity: RpaRuleActionEntity.AiFunction
  },
  {
    text: 'management.rules_management.action_types.entity_type',
    value: RpaRuleActionType.ATTACH_ENTITY,
    actionEntity: RpaRuleActionEntity.AttachEntity
  }
];

export const actionReplyTypes = [
  {
    text: 'COMMENT_MAIL_REPLY',
    value: RpaRuleReplyType.REPLY
  },
  {
    text: 'COMMENT_MAIL_REPLY_ALL',
    value: RpaRuleReplyType.REPLY_ALL
  },
  {
    text: 'COMMENT_MAIL_FORWARD',
    value: RpaRuleReplyType.FORWARD
  },
  {
    text: 'management.rules_management.actions_entity_types.new_comment',
    value: RpaRuleReplyType.TEMPLATED_MESSAGE
  }
];

export const actionReplyChannels = [
  { value: 1, text: 'Email' },
  { value: 5, text: 'Chat' },
  { value: 6, text: 'Internal' },
  { value: 7, text: 'SMS' },
  { value: 20, text: 'Facebook' },
  { value: 21, text: 'Giosg' },
  { value: 23, text: 'WhatsApp' }
];

export const recipientSpecialTypes = [
  {
    text: 'ADD_COMMENT_RECIPIENT_LAST_CONTACT',
    value: '{{lastContactAddress}}'
  },
  {
    text: 'ADD_COMMENT_RECIPIENT_ORIGINAL_CONTACT',
    value: '{{originalContact}}'
  },
  {
    text: 'ADD_COMMENT_RECIPIENT_ATTACHED_ENTITY_MAIL',
    value: '{{entities.email}}'
  },
  {
    text: 'ADD_COMMENT_RECIPIENT_ATTACHED_ENTITY_PHONE',
    value: '{{entities.phone}}'
  }
];

export const getDropdownTargetValuesMap = ({
  aiFunctions,
  channels,
  delegationGroups,
  responseTemplates,
  tagsList,
  ticketPriorities,
  ticketTypes,
  ticketTypesMetadata,
  usersList,
  webhooks
}: {
  aiFunctions: Suggestion[];
  channels: Channel[];
  delegationGroups: Suggestion[];
  responseTemplates: ResponseTemplate[];
  tagsList: Tag[];
  ticketPriorities: Priority[];
  ticketTypes: TicketType[];
  ticketTypesMetadata: TicketTypeMetadata[];
  usersList: UserWithProfile[];
  webhooks: Webhook[];
}): DropdownTargetValuesMap => ({
  [RpaRuleActionType.TICKET_TYPE]: {
    key: 'ticketType',
    list: ticketTypesMetadata.filter((type) => type.allowed).map((type) => ({ text: type.name, value: type.name }))
  },
  [RpaRuleActionType.TICKET_PRIORITY]: {
    key: 'ticketPriority',
    list: ticketPriorities.map((ticketPriority) => ({
      text: tr(ticketPriority.text),
      value: ticketPriority.value,
      icon: ticketPriority.icon,
      color: ticketPriority.color
    }))
  },
  [RpaRuleDropdownType.CHANNEL]: {
    key: 'channel',
    list: channels.map((channel) => ({ text: channel.channel, value: channel.id }))
  },
  [RpaRuleDropdownType.DIRECTION]: {
    key: 'direction',
    list: [
      { text: tr('DIRECTION_IN'), value: 'in' },
      { text: tr('DIRECTION_OUT'), value: 'out' }
    ]
  },
  [RpaRuleDropdownType.STATUS]: {
    key: 'status',
    list: [
      { text: tr('management.rules_management.condition_values.status_success'), value: 'success' },
      { text: tr('management.rules_management.condition_values.status_failed'), value: 'failed' }
    ]
  },
  [RpaRuleDropdownType.ENTITY_TYPES]: {
    key: 'entityType',
    list: getEntityTypes(ticketTypes)
  },
  [RpaRuleActionType.TICKET_DUE_DATE]: {
    key: 'ticketDueDate',
    list: [
      {
        key: 'seconds',
        value: 'seconds',
        text: tr('SETTINGS_SURVEY_SEND_DELAY_UNIT_SECONDS')
      },
      {
        key: 'minutes',
        value: 'minutes',
        text: tr('SETTINGS_SURVEY_SEND_DELAY_UNIT_MINUTES')
      },
      {
        key: 'hours',
        value: 'hours',
        text: tr('SETTINGS_SURVEY_SEND_DELAY_UNIT_HOURS')
      },
      {
        key: 'days',
        value: 'days',
        text: tr('SETTINGS_SURVEY_SEND_DELAY_UNIT_DAYS')
      }
    ]
  },
  [RpaRuleActionType.TICKET_STATE]: {
    key: 'ticketState',
    list: [
      {
        text: tr('CASE_STATUS_TODO'),
        icon: 'exclamation',
        value: 'todo'
      },
      {
        text: tr('CASE_STATUS_DOING'),
        icon: 'clock',
        value: 'doing'
      },
      {
        text: tr('CASE_STATUS_DONE'),
        icon: 'check',
        value: 'done'
      }
    ]
  },
  [RpaRuleActionType.TICKET_DELEGATIONS]: {
    key: 'ticketDelegation',
    list: [
      ...usersList.map((user) => ({
        text: user.loginName,
        value: user.UID
      })),
      ...delegationGroups.map((dGroup) => ({
        text: tr('DELEGATION_GROUP') + ': ' + dGroup.name,
        value: 'DELGROUP' + dGroup.id
      })),
      {
        text: '"handledByUser"',
        value: 'handledByUser'
      }
    ]
  },
  [RpaRuleActionType.TICKET_TAG]: {
    key: 'ticketTag',
    list: tagsList.map((tag) => ({
      text: tag.name,
      value: tag.id
    }))
  },
  [RpaRuleActionType.REMOVE_TICKET_TAG]: {
    key: 'ticketTag',
    list: tagsList.map((tag) => ({
      text: tag.name,
      value: tag.id
    }))
  },
  [RpaRuleActionType.REMOVE_ALL_TICKET_DELEGATIONS]: {
    key: 'removeAllDelegations',
    list: [
      {
        text: tr('all'),
        icon: 'exclamation',
        value: 'all'
      }
    ]
  },
  [RpaRuleActionType.REMOVE_TICKET_WORKING_ON]: {
    key: 'removeWorkingOn',
    list: [
      {
        text: tr('all'),
        icon: 'exclamation',
        value: 'all'
      }
    ]
  },
  [RpaRuleActionType.TICKET_CHANNEL]: {
    key: 'ticketChannel',
    list: channels.map((channel) => ({ text: channel.channel, value: channel.id }))
  },
  [RpaRuleActionType.REPLY_COMMENT]: {
    key: 'templateID',
    list: responseTemplates.map((template) => ({
      text: template.name,
      value: template._id
    }))
  },
  [RpaRuleActionType.ATTACH_ENTITY]: {
    key: 'entityType',
    list: getEntityTypes(ticketTypes)
  },
  [RpaRuleActionType.WEBHOOK]: {
    key: 'webhookId',
    list: webhooks.map((webhook) => ({
      text: webhook.name,
      value: webhook.id
    }))
  },
  [RpaRuleActionType.AI_FUNCTION_INVOCATION]: {
    key: 'aiFunctionId',
    list: aiFunctions.map((aiFunction) => ({
      text: aiFunction.name,
      value: aiFunction.id
    }))
  },
  default: {
    key: '',
    list: []
  }
});

export const getIsEntitiesOrCase = (conditionPath = '') => {
  return [ruleFields.entity.value, ruleFields.case.value, '$.ticket.case'].includes(conditionPath);
};
