import { createReducer } from '@reduxjs/toolkit';

import { swipeRight } from '../actions/swipeActionsRTK';
import { activateTab, addHighlightToTab, addTab, closeTab, removeHighlightFromTab } from '../actions/tabActionsRTK';
import { StaticTabs } from '../types/TicketList';
import type { ContentTypesFields } from '../types/Ticket';

export interface TicketTab {
  id: string;
  title: string;
  activeTab: boolean;
  highlighted?: boolean;
  type?: ContentTypesFields;
}

type TabReducerState = {
  ticketTabs: TicketTab[];
};

const initialState: TabReducerState = {
  ticketTabs: []
};

const tabsReducer = createReducer(initialState.ticketTabs, (builder) => {
  builder
    .addCase(addTab, (state, action) => {
      const { id, activeTab = true, type } = action.payload;
      const newTab = {
        id,
        title: `${id}`,
        activeTab,
        highlighted: false,
        type: type
      };

      if (state.find((tab) => tab.id === action.payload.id)) {
        return state;
      }

      return [...state, newTab];
    })
    .addCase(closeTab, (state, action) => {
      if (action.payload === StaticTabs.MAIN_VIEW) {
        return state;
      }

      return state.filter(({ id }) => id !== action.payload);
    })
    .addCase(activateTab, (state, action) => {
      return state.map((tab) => ({
        ...tab,
        activeTab: tab.id === action.payload,
        highlighted: tab.id === action.payload ? false : tab.highlighted // Always remove highlight when activated
      }));
    })
    .addCase(addHighlightToTab, (state, action) => {
      return state.map((tab) => ({
        ...tab,
        highlighted: tab.id === action.payload && tab.activeTab !== true ? true : tab.highlighted // Add highlight when not active tab
      }));
    })
    .addCase(removeHighlightFromTab, (state, action) => {
      return state.map((tab) => ({
        ...tab,
        highlighted: tab.id === action.payload ? false : tab.highlighted // Remove highlight when activated
      }));
    })
    .addCase(swipeRight, () => {
      return [];
    });
});

export default tabsReducer;
