import type { PersonalData, ResponseTemplate, UserWithProfile } from '@eeedo/types';
import type { IziToast } from 'izitoast';

import type { Ticket } from './Ticket';

export const exampleResponseTemplates: ResponseTemplate[] = [
  {
    _id: 'abcdef123456',
    channels: [7, 1, 6, 5],
    ticketTypes: [1, 2],
    content: 'Response template content',
    name: 'Response template #1',
    value: null
  },
  {
    _id: 'abcdef123456-2',
    channels: [7],
    ticketTypes: [1],
    content: 'Response template content for sms',
    name: 'Response template #2',
    value: null
  }
];

export enum ResponseUpdateType {
  REPLACE = 'REPLACE',
  CONCAT_END = 'CONCAT_END',
  CONCAT_START = 'CONCAT_START',
  CONCAT_CURSOR = 'CONCAT_CURSOR'
}

export interface ResponseUpdate {
  type?: ResponseUpdateType;
  content: string;
  insertAtCursor?: boolean;
  [x: string]: unknown;
}

export interface CheckForAndAssignParametersOptions {
  template: ResponseTemplate | { content: string };
  task: Ticket;
  userData?: PersonalData;
  entities?: object[];
  typeParams?: any;
  handlingAgentString: string;
  users: UserWithProfile[];
}

export type ToastButtons = [
  string,
  (
    instance: IziToast,
    toast: HTMLDivElement,
    button: HTMLButtonElement,
    event: MouseEvent,
    inputs: Array<HTMLInputElement>
  ) => void,
  boolean
][];
