import { t } from 'i18next';
import iziToast from 'izitoast';
import queryString from 'query-string';
import * as React from 'react';
import { Translation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Dimmer, Icon, Loader, Menu } from 'semantic-ui-react';

import type { TicketType, UserWithProfile } from '@eeedo/types';
import type { History, Location } from 'history';
import type { TFunction } from 'i18next';
import type { RouteComponentProps } from 'react-router-dom';

import FeatureFlags from './api/FeatureFlags';
import ChannelType from './Components/CommentIconContent/ChannelType';
import TabBarRestore from './Components/TabBar/TabBarRestore';
import TabBarTab from './Components/TabBar/TabBarTab';
import TicketPreviewPopup from './Components/ticketList/TicketPreviewPopup';
import AddNew from './Components/TopBar/AddNew';
import { StaticTabs } from './types/TicketList';
import ShortcutsTopBar from 'src/Components/Shortcuts/ShortcutsTopBar';

import type { TicketTab } from './reducers/tabsReducer';
import type { State } from './types/initialState';
import type { Tab } from './types/Tab';
import type { TabBarMethod } from './types/TabBar';
import type { Template, TemplateContentJson } from './types/Template';
import type { TicketListTab } from './types/TicketList';

import './TabBar.css';

export interface TabBarProps extends RouteComponentProps {
  // state props
  personalData: State['userData'];
  ticketTypes: State['ticketTypes'];
  user?: UserWithProfile;
  mainTab?: TicketTab | TicketListTab;
  tabs?: TicketTab[] | TicketListTab[];
  tickets?: State['detailedTickets'];
  isCreateTabInProgress?: boolean;
  // own props
  history: History;
  location: Location;
  method: TabBarMethod;
  hideCreateNew?: boolean;

  // dispatch props
  onTabClose(id: string, activeId?: string): void;
  onActivateTab(id: string): void;
  refreshMainView: () => void;
  onCreateNewContent?: (defaultTaskType: string, history: History, template?: Template<TemplateContentJson>) => void;
  scrollCaseBottomInstantly?: () => void;
  onAddTab?: (id: string) => void;
}

interface TabBarState {
  timeoutHandle: NodeJS.Timeout | undefined;
  mainViewRefreshDisabled: boolean;
}

class TabBar extends React.Component<TabBarProps, TabBarState> {
  constructor(props: TabBarProps) {
    super(props);

    this.state = {
      timeoutHandle: undefined,
      mainViewRefreshDisabled: false
    };
  }

  componentWillUnmount() {
    if (this.state.timeoutHandle) {
      clearTimeout(this.state.timeoutHandle);
    }
  }

  private createNewTicket = (template?: Template<TemplateContentJson>) => {
    if (this.props.personalData !== null) {
      const defaultTicketType = this.props.ticketTypes.find(
        (ticketType: TicketType) => ticketType.id === this.props.personalData.userPreferences.defaultTicketType
      );
      if (!defaultTicketType) {
        iziToast.error({
          title: t('ERROR'),
          message: t('NO_DEFAULT_TICKETTYPE_FOR_USER')
        });
      } else {
        this.props.onCreateNewContent?.(defaultTicketType.name, this.props.history, template);
      }
    }
  };

  private activateTab = (id: string) => {
    const nextTab = this.props.tabs?.filter((tab) => tab.id === id)[0] as TicketListTab;
    if (nextTab?.filters) {
      window.history.replaceState({}, 'Eeedo', `${window.location.origin}?${queryString.stringify(nextTab.filters)}`);
    }

    const ticket = (this.props.tickets || []).find((ticket) => ticket.id === id);
    switch (ticket?.channel) {
      case ChannelType.Chat:
      case ChannelType.Giosg: {
        this.props.scrollCaseBottomInstantly?.();
        break;
      }
    }

    this.props.onActivateTab(id);
  };

  private navigateToRoot(): void {
    switch (this.props.method) {
      case 'tickets':
      default:
        this.props.history.push('/');
        break;
      case 'infopages':
        this.props.history.push('/infopage');
        break;
    }
  }

  private deleteTab = (e: React.SyntheticEvent, id: string) => {
    e.preventDefault();
    e.stopPropagation();

    const tabs = (this.props.tabs || []).filter((tab) => tab.id !== id);
    if (this.props.mainTab) {
      tabs.push(this.props.mainTab);
    }

    if (!tabs.length) {
      this.navigateToRoot();
    }

    const lastTabId = tabs[tabs.length - 1]?.id;
    const activeTabId = tabs.find(({ activeTab }) => activeTab)?.id;

    if (id !== StaticTabs.MAIN_VIEW) {
      this.props.onTabClose(id, activeTabId || lastTabId);
    }
  };

  private onLinkClick = (address: string) => {
    this.props.history.push(address);
  };

  private translateTitle = (t: TFunction, title: string) => {
    const translatableTitles = ['MAIN_VIEW', 'NEW', 'TAB_NAME_SEARCH'];
    if (translatableTitles.includes(title)) {
      return t(title);
    }

    if (title.substring(0, 17) === 'TAB_NAME_CUSTOMER') {
      return `${t(title.substring(0, 17))}${title.substring(17)}`;
    }

    if (title.startsWith('TSK') || title.startsWith('INF')) {
      return title.substring(3);
    }

    return title;
  };

  private isHighlighted(value: TicketTab | TicketListTab): value is Tab {
    return value?.highlighted === true;
  }

  private onRefreshClick: React.EventHandler<React.SyntheticEvent> = (e) => {
    if (this.state.mainViewRefreshDisabled) {
      return;
    }

    e.stopPropagation();

    this.setState({ mainViewRefreshDisabled: true }, () => {
      const handle = setTimeout(() => {
        this.setState({ mainViewRefreshDisabled: false, timeoutHandle: undefined });
      }, 5000);

      this.setState({ timeoutHandle: handle });
    });

    this.props.refreshMainView();
  };

  private handleMethodClick = (tabId: string) => {
    const { method } = this.props;
    if (method in ['tickets', 'infopage']) {
      this.onLinkClick(`/${method === 'tickets' ? 'case' : 'infopage'}/${tabId}`);
    }
  };

  render() {
    const onSettingsPage = this.props.location.pathname.indexOf('/settings') !== -1;

    return (
      <Translation ns="translations">
        {(t) => (
          <Menu className="TabBar-Menu" style={{ whiteSpace: 'nowrap', width: 'auto' }}>
            {!onSettingsPage && this.props.tabs && (
              <>
                {this.props.mainTab && (
                  <TabBarTab
                    isMain
                    cname={'TabBar-Tab scrollMenuTab'}
                    tab={this.props.mainTab}
                    refreshDisabled={this.state.mainViewRefreshDisabled}
                    onClick={(e) => {
                      e.preventDefault();
                      this.activateTab(StaticTabs.MAIN_VIEW);
                    }}
                    onRefreshClick={this.onRefreshClick}
                  >
                    {this.translateTitle(t, StaticTabs.MAIN_VIEW)}
                  </TabBarTab>
                )}

                <div className="TabBar-Menu-Wrapper-Left">
                  {!onSettingsPage &&
                    this.props.method !== 'contentLists' &&
                    this.props.personalData.permissions?.includes('addContent') &&
                    !this.props.hideCreateNew && (
                      <AddNew method={this.props.method} createContent={this.createNewTicket} />
                    )}
                </div>

                <div className="ui menu TabBar-Menu-Wrapper-Right">
                  {this.props.tabs.map((tab, index) => {
                    const className = `TabBar-Tab${this.isHighlighted(tab) ? ' pulse-active' : ''} scrollMenuTab`;
                    const translatedTitle = this.translateTitle(t, tab.title);
                    const ticket = this.props.tickets?.find((ticket) => ticket.id === tab.id);
                    const type = this.props.ticketTypes.find((t) => t.name === ticket?.taskType);

                    return (
                      <TabBarTab
                        key={index}
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleMethodClick(tab.id);
                          this.activateTab(tab.id);
                        }}
                        onCloseClick={(e) => {
                          e.stopPropagation();
                          this.deleteTab(e, tab.id);
                        }}
                        cname={className}
                        tab={tab}
                      >
                        {(FeatureFlags.isFlagOn('ENABLE_TABBAR_PREVIEW') && ticket && type && (
                          <TicketPreviewPopup
                            fields={type.fieldSets}
                            id={ticket.id}
                            title={translatedTitle}
                            popupTitle={ticket.title}
                            type={type.name}
                            created={ticket.created}
                            touched={ticket.touched}
                            dueDate={ticket.dueDate}
                            position={'bottom center'}
                            data={{
                              comments: ticket.comments,
                              entities: ticket.entities,
                              editedByUser: ticket.editedByUser
                            }}
                            onLoaded={() => this.forceUpdate()}
                          />
                        )) ||
                          translatedTitle}
                      </TabBarTab>
                    );
                  })}

                  {!onSettingsPage && (this.props.method === 'tickets' || this.props.method === 'infopages') && (
                    <TabBarRestore contentType={this.props.method} />
                  )}
                </div>
              </>
            )}

            {onSettingsPage && (
              <Menu.Item id="settingsTabBarHeader" className="active icon item TabBar-Tab">
                {t('PREFERENCES_EDIT_SETTINGS')}
                <Icon className="TabBar-Icon" name="settings" />
              </Menu.Item>
            )}

            {this.props.isCreateTabInProgress && (
              <Menu.Item>
                <Loader size="tiny" active inline />
                <Icon className="TabBar-Delete-Icon" name="delete" />
                <Dimmer active inverted />
              </Menu.Item>
            )}

            <ShortcutsTopBar onCreateNewTicket={this.createNewTicket} />
          </Menu>
        )}
      </Translation>
    );
  }
}

export default withRouter(TabBar);
