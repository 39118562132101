import { removeHTMLTags } from '@eeedo/utils';
import iziToast from 'izitoast';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, ButtonGroup, Popup } from 'semantic-ui-react';

import type { FC, SyntheticEvent } from 'react';
import type { ConnectedProps } from 'react-redux';

import ArticleItemHeading from './ArticleItemHeading';
import ArticleItemTags from './ArticleItemTags';
import { setCaseIFrame } from 'src/actions/caseIFrameActions';
import { updateTicketTabState } from 'src/actions/draftActions';
import EnvSettings from 'src/api/EnvSettings';
import FeatureFlags from 'src/api/FeatureFlags';
import { getSelectedChannelTab } from 'src/Components/CommentIconContent/ChannelType';
import { addFloatingWindow } from 'src/reducers/floatingWindowsReducer';
import { FloatingWindowTypes } from 'src/types/FloatingWindow';
import { tagIdToString } from 'src/types/Tag';
import { highlightContentByInput } from 'src/Utilities/highlighter';
import { parseContent } from 'src/Utilities/parseUtils';
import { insertTemplateToContent, onResponseTemplatePrompt } from 'src/Utilities/replyContent';
import { decodeHtml, sanitizeHTML } from 'src/Utilities/sanitize';
import { getTags } from 'src/Utilities/tags';
import { nonHTMLChannels } from 'src/Utilities/templates';
import { taskIdToNumericalId } from 'src/Utilities/ticketList';

import type { Draft } from 'src/reducers/draftsReducer';
import type { ListArticle } from 'src/types/Articles';
import type { State } from 'src/types/initialState';

import './ArticleListItem.css';

import semanticStyles from '../../../../../../../../../node_modules/semantic-ui-css/semantic.min.css?inline';

const buildUrl = (baseUrl: string, path: string) => {
  const url = new URL(baseUrl);
  url.pathname = path;

  return url.href;
};

interface ArticleListItemProps extends ConnectedProps<typeof connector> {
  item: ListArticle;
  term: string;
  open: boolean;
  viewId?: string;

  onClick?(): void;
}

const ArticleListItem: FC<ArticleListItemProps> = ({
  term,
  item,
  open,
  viewId,
  allTags,
  contentId,
  draftContent,
  selectedChannelTab,
  onClick,
  setCaseIFrame,
  addFloatingWindow,
  updateTicketTabState
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const tags = getTags(item.tags?.map((tagId) => tagIdToString(tagId)) ?? [], allTags);
  const content = parseContent(item.content, item.attachments, true);
  const kbUrl = EnvSettings.getSettings().KNOWLEDGE_BASE_URL;
  const hasPublicUrl = !!kbUrl && !!viewId;

  const useForReply = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      let newContent: string = content;

      if (draftContent) {
        const update = await onResponseTemplatePrompt({
          update: { content: newContent },
          insertAtCursor: false,
          tr: t
        });

        if (update?.content) {
          newContent = insertTemplateToContent(update.content, draftContent, update.type);
        }
      }

      if (contentId && selectedChannelTab) {
        newContent = nonHTMLChannels.includes(selectedChannelTab)
          ? removeHTMLTags(newContent, decodeHtml)
          : sanitizeHTML(newContent);
        updateTicketTabState(contentId, { [selectedChannelTab]: { content: newContent } });
      }
    },
    [draftContent, selectedChannelTab, contentId]
  );
  const editArticle = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    history.push(`/infopage/${item.id}`);
  }, []);
  const openArticle = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const htmlContent = `<style>${semanticStyles}</style><div style="padding: 1rem">${content}</div>`;
      const blob = new Blob([htmlContent], { type: 'text/html' });
      const url = URL.createObjectURL(blob);

      if (contentId) {
        setCaseIFrame({
          id: taskIdToNumericalId(contentId),
          url,
          tabTitle: item.title
        });
      }
    },
    [contentId]
  );
  const openArticleWindow = useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    addFloatingWindow({
      id: item.id,
      title: item.title,
      content,
      type: FloatingWindowTypes.Infopage,
      data: item
    });
  }, []);
  const copyPublicLink = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      navigator.clipboard.writeText(buildUrl(kbUrl, `/v/${viewId}/a/${item.id.substring(3)}`));
      iziToast.info({
        message: t('COPIED')
      });
    },
    [kbUrl, viewId]
  );

  const preventClick = useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <div>
      <div className="article-list-item" onClick={onClick}>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '5px' }}>
          <ArticleItemHeading title={item.title} term={term} />

          <ButtonGroup>
            <Popup
              trigger={<Button icon="window restore outline" onClick={openArticleWindow} />}
              content={t('OPEN_IN_WINDOW')}
            />
            <Popup
              pinned
              on="click"
              position="top right"
              trigger={<Button icon="ellipsis horizontal" onClick={preventClick} />}
              content={
                <ButtonGroup>
                  {FeatureFlags.isFlagOn('ENABLE_FLOATING_WINDOW') && (
                    <Popup
                      trigger={<Button icon="window maximize" onClick={openArticle} />}
                      content={t('GENERAL_PREVIEW')}
                    />
                  )}
                  <Popup trigger={<Button icon="reply" onClick={useForReply} />} content={t('USE_FOR_REPLY')} />
                  <Popup trigger={<Button icon="edit" onClick={editArticle} />} content={t('GENERAL_EDIT')} />
                  {hasPublicUrl && (
                    <Popup
                      trigger={<Button icon="copy" onClick={copyPublicLink} />}
                      content={t('COPY_PUBLIC_LINK_TO_CLIPBOARD')}
                    />
                  )}
                </ButtonGroup>
              }
            />
          </ButtonGroup>
        </div>
        {tags?.length > 0 && <ArticleItemTags keyPrefix={`article-item-tag-${item.title}`} tags={tags} />}
      </div>
      {open && (
        <div
          className="article-list-item-content"
          dangerouslySetInnerHTML={{
            __html: sanitizeHTML(term ? highlightContentByInput({ content, input: term }) : content || '')
          }}
        />
      )}
    </div>
  );
};

const connector = connect(
  (state: State) => {
    const activeTicketTab = state.activeTicketTab;
    const draft = (activeTicketTab ? state.drafts[activeTicketTab] : undefined) as Draft | undefined;
    const selectedChannelTab = getSelectedChannelTab(activeTicketTab, state.detailedTickets, draft);
    const draftContent = (selectedChannelTab && draft?.[selectedChannelTab]?.content) ?? '';

    return {
      contentId: activeTicketTab,
      draftContent,
      selectedChannelTab,
      allTags: state.tags.filter((tag) => tag.name !== 'default'),
      categories: state.categories
    };
  },
  { updateTicketTabState, addFloatingWindow, setCaseIFrame }
);

export default connector(ArticleListItem);
